const videoEmbedHandler = function videoEmbedHandler(poster) {
  return function (event) {
    event.preventDefault();
    const iframe = poster.parentNode.querySelector('iframe');
    iframe.src = iframe.dataset.src;
    poster.parentNode.removeChild(poster);
    iframe.style.display = 'block';
  };
};

const posters = document.querySelectorAll('.ex-youtubevideo__poster');

[].forEach.call(posters, function (poster) {
  poster.addEventListener('click', videoEmbedHandler(poster));
  poster.addEventListener('keydown', event => {
    if(event.keyCode == 13 || event.keyCode == 32) {
      videoEmbedHandler(poster)(event);
    }
  });
});